.section__appcart {
  
  position: fixed;
  left: 632px;
  top: 0;
  width: 300px;
  z-index: 1001;
  background-color: #fff;
  -moz-box-shadow: 0 0 5px #888;
  -webkit-box-shadow: 0 0 5px#888;
  box-shadow: 0 0 5px #888;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.9s ease;

      
   @media(max-width:900px){
      // .cart{
    left: 50%;
    transform: translateX(-50%);
          
      // }
    }

    @media(max-width:767px){
      // .cart{
      left: 50%;
      transform: translateX(-50%);
      // }
    }

    @media(max-width:589px){
      // .cart{
      left: 0px;
      width: 100%;
      transform: translateX(0%);
      // }
    }

    & p {
      margin: 0;
    }
    
    & p i{
      margin: 0;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 20px;
      padding: 5px 5px;
      -webkit-box-shadow: 0px 1px 10px 0px rgba(198,198,204,0.5);
      -moz-box-shadow: 0px 1px 10px 0px rgba(198,198,204,0.5);
      box-shadow: 0px 1px 10px 0px rgba(198,198,204,0.5);

      &:hover {
        cursor: pointer;
      }
    }
    &-body {
      padding: 10px 10px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      } 
    }

    &-footer {
      padding: 10px;
      -webkit-box-shadow: 0px 1px 10px 0px rgba(198,198,204,0.5);
      -moz-box-shadow: 0px 1px 10px 0px rgba(198,198,204,0.5);
      box-shadow: 0px 1px 10px 0px rgba(198,198,204,0.5);
      font-size: 18px;
      font-weight: bold;
    }

    &-products {
      display: flex;
      margin-bottom: 3px;
      -webkit-box-shadow: 0px 2px 10px 0px rgba(198,198,204,0.43);
      -moz-box-shadow: 0px 2px 10px 0px rgba(198,198,204,0.43);
      box-shadow: 0px 2px 10px 0px rgba(198,198,204,0.43);
        &-img {
        width: 100px !important;
        height: 100px !important;
        }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding-left: 2px;
      padding-right: 4px;
      & p {
        margin: 0;
      }

      &-precio {
        display: flex;
        justify-content: space-between;
        font-weight: bold;

        & > a {
          margin: 0;
          font-size: 16px;
        }

        &-cantidad {
          display: flex;
          justify-content: space-between;

          & p {
            margin: 0 10px;
          }
        }
      }
    }
}
  