.section__appheader {
  max-width: 930px;
  font-size: 0.6rem;
  color: #fff;

  &-top {
    background: $color-cambio-sitio;
    
    font-weight: 400;
    min-height: 27px;

    display: flex;
    justify-content: center;
    align-items: center;

  &-content {
      &-inicio {
        font-size: 0.8rem;
        max-width: 160px;
        cursor: pointer;
      }
      &-text {
        text-transform: uppercase;
        text-align: center;
      }
      &-link {
        cursor: pointer;
       // color: white !important;     
       color: white;
        text-decoration: none;
      }
      &-session {
        color: $color-cambio-color-titulo;
        display: flex;
        justify-content: end; 
        min-height: 27px;
      }
    }
  }

  &-button {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &-content {
      &-logo {
        width:80px;
        height:80px;
        margin-left: 10px;
      }
      &-botones {
        font-size: 0.7rem;
        text-transform: uppercase;
        text-align: center;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        & a {
          text-decoration: none;
          font-weight: bold;
          color: $color-cambio-color-titulo;
          line-height: .7rem;
          text-align: left;
        }
      }
      &-redes {
        display: flex;
        justify-content: space-around;
        padding-left: 0px;
      }
      &-input {
        border-radius: 0;
        height: 30px;
        padding-left: 5px;
      }
   }
 }
 &-menu-icon {
    display: flex;
    align-items: center;
 }
}
